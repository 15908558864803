import { useMutation, useQuery } from "@apollo/react-hooks";
import { RouteComponentProps } from "@reach/router";
import { notification, Spin } from "antd";
import idx from "idx";
import React, { FC, useCallback, useMemo } from "react";
import GET_USER_BY_ID from "./getUserById.graphql";
import {
  GetUserById,
  GetUserById_user as User,
  GetUserByIdVariables,
} from "./types/GetUserById";
import { UpdateUser, UpdateUserVariables } from "./types/UpdateUser";
import UPDATE_USER from "./updateUser.graphql";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import UserForm, { FormType } from "components/userForm";
import withRouter from "components/withRouter";
import routes, { UserEditParams } from "routes";
import { UpdateUserDto } from "types/graphql-global-types";
import { pick, useErrorHandler, useNavigate } from "utils";

type Props = RouteComponentProps<UserEditParams>;

const EditUser: FC<Props> = ({ id = "" }) => {
  const { loading, data, error } = useQuery<GetUserById, GetUserByIdVariables>(
    GET_USER_BY_ID,
    {
      fetchPolicy: "cache-and-network",
      variables: { id },
    }
  );

  const [updateUser, { loading: updating, error: updateError }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UPDATE_USER, {
    refetchQueries: ["GetUserList"],
  });

  useErrorHandler([error, updateError]);

  const user = useMemo<Partial<User>>(() => idx(data, x => x.user) || {}, [
    data,
  ]);

  const initialValues = useMemo<Partial<UpdateUserDto>>(
    () => ({
      id: parseInt(user.id || ""),
      isPhoneConfirmed: user.isPhoneNumberConfirmed || false,
      subscribeForActsOfReconciliation: false,
      ...pick(
        user,
        "emailAddress",
        "isActive",
        "isEmailConfirmed",
        "name",
        "phoneNumber",
        "roleNames",
        "surname",
        "userName"
      ),
    }),
    [user]
  );

  const navigateToUsers = useNavigate(routes.users);

  const handleSubmit = useCallback(
    async (input: UpdateUserDto) => {
      const { errors, data: updateData } = await updateUser({
        variables: {
          input: { ...initialValues, ...input },
        },
      });

      if (!errors) {
        notification.success({
          message: "Успех!",
          description: (
            <>
              Пользователь{" "}
              <strong>
                {idx(updateData, x => x.srv.user.update.userName)}
              </strong>{" "}
              успешно обновлён
            </>
          ),
        });
      }

      navigateToUsers();
    },
    [updateUser, initialValues, navigateToUsers]
  );

  return (
    <Authorize>
      <MainLayout
        title={`Редактирование пользователя "${idx(
          data,
          x => x.user.userName
        )}"`}
        subtitle={idx(data, x => x.user.emailAddress)}
        onBack={navigateToUsers}
      >
        <Spin spinning={loading || updating}>
          <UserForm
            initialValues={initialValues}
            type={FormType.Update}
            buyer={user.buyer || undefined}
            onSubmit={handleSubmit}
          />
        </Spin>
      </MainLayout>
    </Authorize>
  );
};

export default withRouter({ path: routes.userEdit })(EditUser);
