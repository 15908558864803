import { RouteComponentProps, Router } from "@reach/router";
import { navigate } from "gatsby";
import React, { ComponentType, FC, useEffect } from "react";
import routes from "routes";

type WithRouteProps = Pick<RouteComponentProps, "default" | "path">;
type WithRouterProps = WithRouteProps & { disableNotFound?: boolean };
type NotFoundProps = WithRouteProps;

export const NotFound: FC<NotFoundProps> = () => {
  useEffect(() => navigate(routes.x404));

  return null;
};

export const withRoute = <T extends object>(Component: ComponentType<T>) => (
  props: T & WithRouteProps
) => <Component {...props} />;

const withRouter = <T extends object>({
  disableNotFound = false,
  ...routeProps
}: WithRouterProps) => (Component: ComponentType<T>) => {
  const RouteComponent = withRoute(Component);

  return (props: T) => (
    <Router>
      <RouteComponent {...props} {...routeProps} />
      {!disableNotFound && <NotFound default={true} />}
    </Router>
  );
};

export default withRouter;
